
import logo from './logo.svg';
import './App.css';

import Homepage from './Pages/Homepage/Homepage';

function App() {
  return (
    <div className="App">
      <Homepage/>
    </div>
  );
}

export default App;
