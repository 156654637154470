import React from "react";
import {Row, Col, Space} from "antd";

import './Homepage.css';
import billinetLogo from "../../Assets/Images/billinetLogo.png";
import leyteAgriLogo from "../../Assets/Images/leyteAgriLogo.png";
import systemDevelopmentLogo from "../../Assets/Images/systemDevelopmentLogo.png";
import vdcs from "../../Assets/Images/vdcsLogo.png";
import hrSystemLogo from "../../Assets/Images/hrSystemLogo.png";
import taskManagerLogo from "../../Assets/Images/taskManagerLogo.png";
import wadAndCo from "../../Assets/Images/wadAndCoLogo.png";
import serendipiteLogo from "../../Assets/Images/serendipiteLogo.png";
import tgcLogo from "../../Assets/Images/tgcLogo.png";


export default function Homepage() {

    const handleNavigation = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div>
            <Row className="d-flex card-container">
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://bilinet.wadandco.com/")}>
                    <img src={billinetLogo} alt="" className="homepage-logos"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://lavc.wadcompany.com/")}>
                    <img src={leyteAgriLogo} alt="" className="homepage-logos"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://sdvc.wadandco.com/")}>
                    <img src={systemDevelopmentLogo} alt="" className="homepage-logos"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://victoria.wadandco.com/")}>
                    <img src={vdcs} alt="" className="homepage-logos"/>
                </Col>

                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://hr.wadandco.com/")}>
                    <img src={hrSystemLogo} alt="" className="homepage-logos hrSystemLogo"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://taskmanager.wadandco.com/")}>
                    <img src={taskManagerLogo} alt="" className="homepage-logos taskManagerLogo"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://warehouse.wadandco.com/")}>
                    <img src={wadAndCo} alt="" className="homepage-logos"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://serendipite.wadandco.com/")}>
                    <img src={serendipiteLogo} alt="" className="homepage-logos"/>
                </Col>
                <Col xs={12} md={6} lg={4} className="homepage-cards" onClick={() => handleNavigation("https://tgc.wadandco.com/")}>
                    <img src={tgcLogo} alt="" className="homepage-logos"/>
                </Col>
            </Row>
        </div>
    )
}